import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef} from 'react'
import { useSignupMutation } from '../../features/auth/authApiSlice';
import Loader from '../Loader';

const SignupCard = () => {
  const navigate = useNavigate()
  const [signup, { isLoading }] = useSignupMutation()
  
  const userRef = useRef()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [fullName, setFullName] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [submitted, setSubmitted] = useState(false);
  let content

 

  useEffect(()=>{
    userRef.current.focus()
  }, [])

  useEffect(()=>{
    setErrMsg('');
  }, [email, password])

  const handleSubmit = async (e)=>{
     e.preventDefault()
     try{
        await signup({ email, password, phone, fullName}).unwrap()
        setEmail('')
        setPassword('')
        setSubmitted(true)
     }catch(err) {
       if(!err.status){
          setErrMsg('No Server Response')

       }else if(err.status === 400){
          setErrMsg('Missing Email or Password')
       } else if(err.status === 401){
          setErrMsg('Unauthorized')
       } 
       else if(err.status === 402){
        setErrMsg('Incorrect Password')
      } else{
          setErrMsg(err.data?.message);
       }
       errRef.current.focus();
     }
  }
  const handleEmailInput =(e) => setEmail(e.target.value)
  const handlePwdInput= (e) => setPassword(e.target.value)
  const handlePhoneInput =(e) => setPhone(e.target.value)
  const handleFullNameInput =(e) => setFullName(e.target.value)

  const errClass = errMsg ? "errmsg" : "offscreen"
  const onLoginClick= ()=>{
    navigate('/login')
  }
  if (isLoading) return <Loader />
  
  return (
    <div className="py-5 px-6 bg-white w-[95%] sm:w-[75%] lg:max-w-[400px] m-auto rounded-[2rem] ">
      {submitted ? (
        <>
        <h1 className="mb-6 uppercase font-semibold text-4xl text-center">
            Sign Up
         </h1>
        <p className="text-center mt-8 text-lg font-semibold">
          You are successfully signed up you can login now
        </p>
        <div className="text-center">
        
        <a className="text-red-600 font-semibold cursor-pointer uppercase" onClick={onLoginClick}>
          Login?
        </a>
        </div>
        </>
      ) : (
        <>
        <h1 className="mb-6 uppercase font-semibold text-4xl text-center">
        Sign Up
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label className="font-semibold inline-block" htmlFor="fullname">
            Full Name
          </label>
          <input
            type="text"
            id="fullname"
            placeholder="Enter your Full Name"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={fullName}
            onChange={handleFullNameInput}
          />
        </div>

        <div className="mb-2">
          <label className="font-semibold inline-block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Enter your Email"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={email}
            onChange={handleEmailInput}
          />
        </div>

        <div className="mb-2">
          <label className="font-semibold inline-block mb-1" htmlFor="username">
            Phone
          </label>
          <input
            type="text"
            id="phone"
            placeholder="Enter your Phone"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={phone}
            onChange={handlePhoneInput}
          />
        </div>

        <div className="mb-2">
          <label className="font-semibold inline-block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={password}
            onChange={handlePwdInput}
          />
        </div>

        <div className="mb-2">
          <label className="flex align-center gap-2 text-red-600">
            <input type="checkbox" /> Our terms and conditions
          </label>
        </div>

        <div className=" text-center">
          <button className="py-2 px-8 rounded-full text-lg font-semibold text-white bg-black uppercase"
            type="submit"
          >
            SIGN UP
          </button>
        </div>
      </form>

      <div className="text-center">
        <p className="mt-2 font-semibold">OR</p>
        <a className="text-red-600 font-bold cursor-pointer uppercase" onClick={onLoginClick}>
          LOG IN
        </a>
      </div>
        </>
      )
      }
      
    </div>
  );
};

export default SignupCard;
