// AppProvider.js
import React, { createContext, useState } from "react";

export const AppContext = createContext({
  question: [],
  accept: [],
  slidesName: [],
  selectedSlide: [],
});

const slidesName = [
  "cover",
  "introduction",
  "requirement analysis",
  "development approach",
  "development commercials",
  "contact",
  "reviews",
  
];

export default function AppProvider({ children }) {
  const global = {
    question: useState(false),
    accept: useState(false),
    slidesName: slidesName,
    selectedSlide: useState("cover"),
  };

  return <AppContext.Provider value={global}>{children}</AppContext.Provider>;
}
