import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useEffect} from 'react'
import { selectCurrentToken } from "../../features/auth/authSlice"
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useSendLogoutMutation } from '../../features/auth/authApiSlice';

const Header = ({onSidebar}) => {
  
  const { id } = useParams();

  const navigate = useNavigate();
  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  const onLogoutClick = () => sendLogout();

  let AdminName
  const token = useSelector(selectCurrentToken)
  if(token){
    const decoded = jwtDecode(token);
    const { fullName, email, role} = decoded.UserInfo;
    AdminName= fullName
  }
  return (
    <div className="w-full h-16 px-4 sticky top-0 bg-white z-50">
      <div className="h-16 flex justify-between items-center">
        <div className="w-[140px] h-fit px-4 flex items-center justify-center gap-4">
          <button onClick={onSidebar} disabled={id === undefined || !id}>
            <span className="material-symbols-outlined text-3xl" >menu</span>
          </button>
          <img
            src="/assets/images/alphabetLogoDark.png"
            className="object-fill"
          />
        </div>
        <div className="flex items-center gap-2 sm:gap-4">
          <p className="font-semibold text-right hidden sm:block">Welcome, {AdminName}!</p>
          <button
        className="h-[36px] px-6 rounded-full text-md font-semibold text-white bg-black hover:bg-red-600 transition-colors duration-300"
        onClick={onLogoutClick}
      >
        LOGOUT
      </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
